<template>
    <div class="container">
        <div class="navsteps">
            <div class="navsteps__item active">
                <div class="number">1</div>
                <div class="descr">Разрахунок</div>
            </div>
            <div class="navsteps__item">
                <div class="number">2</div>
                <div class="descr">Вибір СК</div>
            </div>
            <div class="navsteps__item">
                <div class="number">3</div>
                <div class="descr">Оформлення</div>
            </div>
        </div>
        <TourismSearchForm />
    </div>
</template>
<script>
import TourismSearchForm from '@/views/tourism/components/TourismSearchForm.vue'
export default {
    components: {TourismSearchForm},
}
</script>
<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
